@import "poppins.css";

.text-large-title {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 37px;
    line-height: 52px;
}

.text-title1 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 31px;
    line-height: 44px;
}

.text-title2 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
}

.text-title3 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 23px;
    line-height: 32px;
}

.text-headline {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
}

.text-body {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
}

.text-callout {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
}

.text-subhead {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
}

.text-footnote {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
}

.text-caption1 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}

.text-caption2 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.text-caption3 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
}
