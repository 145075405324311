@import "poppins.css";
@import "typography.css";
@import "forms.css";

body {
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .text-link {
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;

        @apply underline text-accent-900;
    }

    .link-text-primary {
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;

        @apply underline text-primary-900 uppercase;
    }

    .link-text-accent {
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;

        @apply underline text-accent-900 uppercase;
    }
}
