@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins/Poppins-Light.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins/Poppins-LightItalic.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins/Poppins-Italic.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Poppins-Medium';
  src: url('../public/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins/Poppins-MediumItalic.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
       font-weight: 600;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins/Poppins-SemiBoldItalic.ttf') format('truetype');
       font-weight: 600;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Poppins-Bold';
  src: url('../public/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins/Poppins-BoldItalic.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: italic;
}
