.input-checkbox:checked[type=checkbox] {
    background-image: url("/images/ic_checkbox_circle_accent.svg");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .button-primary {
        @apply bg-accent-900 px-[16px] py-[12px] rounded-[8px]
            text-white-900 uppercase
            disabled:bg-accent-400 !important;

        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
    }

    .button-accent-filled {
        @apply bg-accent-900 px-[16px] py-[12px] rounded-[8px]
            text-white-900 uppercase
            disabled:bg-accent-400 !important;

        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
    }

    .button-accent-lined {
        @apply bg-white-900 px-[16px] py-[12px] rounded-[8px]
            border-[1px] border-accent-900
            text-accent-900 uppercase
            hover:bg-accent-100
            disabled:text-black-500 disabled:border-black-200 !important;

        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
    }

    .button-meeting-booking {
        @apply bg-white-900 px-[16px] py-[12px] rounded-[8px]
            border-[1px] border-accent-900
            text-accent-900 uppercase
            hover:bg-accent-100 disabled:hover:bg-black-100
            disabled:text-black-300 disabled:bg-black-50 disabled:border-transparent !important;

        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
    }

    .button-enter-meeting-live {
        @apply bg-accent-900 px-[16px] py-[12px] rounded-[8px]
            border-[1px] border-accent-900
            text-white-900 uppercase
            disabled:hover:bg-black-100
            disabled:text-black-300 disabled:bg-black-50 disabled:border-transparent !important;

        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
    }

    .button-enter-meeting {
        @apply bg-white-900 px-[16px] py-[12px] rounded-[8px]
            border-[1px] border-black-900
            text-black-700 uppercase
            disabled:hover:bg-black-100
            disabled:text-black-300 disabled:bg-black-50 disabled:border-transparent !important;

        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
    }

    .input-checkbox {
        @apply appearance-none w-[32px] h-[32px] min-w-[32px] min-h-[32px]
            border-[4px] border-black-500 rounded-full
            bg-white-900 checked:bg-accent-900 checked:border-accent-900
            focus:outline-none
            align-top
            bg-no-repeat bg-center bg-contain
            float-left mr-[12px]
            cursor-pointer;
    }

    .chip-primary {
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;

        @apply px-[16px] py-[12px] border-[1px] border-primary-900
            hover:bg-primary-100 rounded-[8px]
            text-primary-900
            cursor-pointer;
    }

    .chip-primary-selected {
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;

        @apply px-[16px] py-[12px] border-[1px] border-primary-900
            bg-primary-900 rounded-[8px] hover:bg-primary-800
            text-white-900
            cursor-pointer;
    }
}
